import React from "react";
import classNames from "classnames";

import { Carousel } from "@molecules";
import { Container, Text, Image } from "@atoms";

const LogoCarousel = ({ bgColor, className: _className, copy, slides }) => {
  return (
    <section
      className={classNames(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container variant="xl">
        {copy && (
          <Text
            richText
            className={classNames(
              "max-w-xl mx-auto mt-4 text-center prose-p:text-lg lg:prose-p:text-xl",
              {
                "prose-dark": bgColor === "midnight",
              }
            )}
          >
            {copy}
          </Text>
        )}
        <div className="mt-12 px-4 md:px-8">
          <Carousel minVisible={2} maxVisible={5} showInactive>
            {slides?.map(slide => (
              <div className="h-24 px-4 md:px-8" key={slide.uid}>
                <div className="relative h-full">
                  <Image
                    image={slide.image}
                    objectFit="contain"
                    fill
                    transparent
                    draggable={false}
                    className="text-white brightness-[3] grayscale"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

LogoCarousel.defaultProps = {
  bgColor: "midnight",
};

export default LogoCarousel;
